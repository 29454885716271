<script setup>
import { computed, onMounted, onBeforeMount, reactive, watchEffect } from 'vue';
import TabItem from './TabItem.vue';
import BetaAssetsContract from '@/contractsApi/polygon/BetaAssetsContract';
import { getTotalSupply } from './GetTotalSupply';
import {
  BVT_TOKEN_ADDR,
  BVG_TOKEN_ADDR,
  CATTLE_ADDR,
  BLIND_BOX_ADDR,
  BETA_ASSETS_ADDR,
  STAR_ADDR,
} from '@/contracts/polygonAddress';
import { useAppStore } from '@/store/appStore';
import { useRoute } from 'vue-router';

const appStore = useAppStore();
const route = useRoute();

const betaAssetsObj = reactive(new BetaAssetsContract());

// 获取 totalSupply
getTotalSupply();

// 选项列表
const tabList = reactive([
  // 星球
  {
    id: 1,
    img: require('@img/claimAssets/icon-planet.png'),
    text: 'claimAssets.1',
    active: true,

    img: require('@img/claimAssets/img-planet.png'),
    name: 'claimAssets.1',
    desc: 'claimAssets.2',
    contract: STAR_ADDR,
    supply: 350, // 發行量
    // supplyText: '150', // 发行量展示
    whoEligible: 'claimAssets.17', // 白名單 or 所有人
    eligible: false, // 是不是在白名單
    claimed: false, // 是否领取过星球
    canGet: 1, // 每個地址可領取

    resNum: 0, // 星球剩餘數量

    minted: 0, // 每個人領取 * 所有人領取的次數
    banClaim: false, // 是否可以点击领取

    loadClaim: false, // 是否领取中
    lockClaim: false, // 是否锁定领取按钮
  },
  // 牛牛
  {
    id: 2,
    img: require('@img/claimAssets/icon-bovineHero.png'),
    text: 'claimAssets.5',
    active: false,

    img: require('@img/claimAssets/img-bovineHero.png'),
    name: 'claimAssets.5',
    desc: 'claimAssets.6',
    contract: CATTLE_ADDR,
    supply: 514,
    // supplyText: '514', // 发行量展示
    whoEligible: 'claimAssets.17',
    eligible: false,
    claimed: false,
    canGet: 1, // 每個地址可領取

    resNum: 0, //

    minted: 0, // 每個人領取 * 所有人領取的次數
    banClaim: false, // 是否可以点击领取

    loadClaim: false, // 是否领取中
    lockClaim: false, // 是否锁定领取按钮
  },
  // 盲盒
  {
    id: 3,
    img: require('@img/claimAssets/icon-cattle-mystery-box.png'),
    text: 'claimAssets.3',
    active: false,

    img: require('@img/claimAssets/img-cattle-mystery-box.png'),
    name: 'claimAssets.26',
    desc: 'claimAssets.4',
    contract: BLIND_BOX_ADDR,
    supply: 'claimAssets.28',
    whoEligible: 'claimAssets.24',
    eligible: true,
    claimed: true,
    canGet: 2, // 每個地址可領取

    minted: 0, // 每個人領取 * 所有人領取的次數
    banClaim: false, // 是否可以点击领取

    loadClaim: false, // 是否领取中
    lockClaim: false, // 是否锁定领取按钮
  },
  // bvt
  {
    id: 4,
    img: require('@img/claimAssets/icon-bvt.png'),
    text: 'TBVT',
    active: false,

    img: require('@img/claimAssets/img-bvt.png'),
    name: 'TBVT',
    desc: 'claimAssets.22',
    contract: BVT_TOKEN_ADDR,
    supply: 730000000,
    whoEligible: 'claimAssets.24',
    eligible: true,
    claimed: false,
    canGet: 50, // 每個地址可領取

    minted: 0, // 每個人領取 * 所有人領取的次數
    banClaim: false, // 是否可以点击领取

    loadClaim: false, // 是否领取中
    lockClaim: false, // 是否锁定领取按钮
  },
  // bvg
  {
    id: 5,
    img: require('@img/claimAssets/icon-bvg.png'),
    text: 'TBVG',
    active: false,

    img: require('@img/claimAssets/img-bvg.png'),
    name: 'TBVG',
    desc: 'claimAssets.21',
    contract: BVG_TOKEN_ADDR,
    supply: 'claimAssets.28',
    whoEligible: 'claimAssets.24',
    eligible: true,
    claimed: false,
    canGet: 1000, // 每個地址可領取

    minted: 0, // 每個人領取 * 所有人領取的次數
    banClaim: false, // 是否禁止点击领取（供应量不足）

    loadClaim: false, // 是否领取中
    lockClaim: false, // 是否锁定领取按钮
  },
]);

async function checkInfo() {
  const betaAssets = betaAssetsObj.checkInfo();
  const [info] = await Promise.all([
    betaAssets,
    // getTotalSupply(),
  ]);

  tabList[0].claimed = info.hasGetStar; // 是否领取过星球
  tabList[1].claimed = info.hasGetCalf; // 是否领取过牛牛
  tabList[2].claimed = info.hasGetBox; // 是否领取过盲盒
  tabList[3].claimed = info.hasGetBvt; // 是否领取过bvt
  tabList[4].claimed = info.hasGetBvg; // 是否领取过bvg

  tabList[0].eligible = info.isStarWhite; // 是不是星球白名单
  tabList[1].eligible = info.isCalfWhite; // 是不是牛牛白名单

  tabList[0].resNum = info.starRes; // 星球剩餘數量
  tabList[0].minted = tabList[0].supply - info.starRes; // 星球剩餘數量
  tabList[1].resNum = info.calfRes; // 牛牛剩餘數量

  // tabList[0].minted = tabList[0].supply; // 星球,starTotal是minted的总量，但现在这里是取所有发行量
  tabList[1].minted = tabList[1].supply; // 牛牛,cattleTotal是minted的总量，但现在这里是取所有发行量
  tabList[2].minted = info.boxHasGet; // 盲盒
  tabList[3].minted = info.bvtHasGet; // bvt
  tabList[4].minted = info.bvgHasGet; // bvg

  tabList[0].supply = info.starTotal;
  tabList[3].supply = info.bvtTotal;


  tabList.forEach(tab => {
    // console.log('每一项', tab.name, +tab.minted, +tab.supply,+tab.minted >= +tab.supply);
    tab.banClaim = +tab.minted >= +tab.supply;
  });
}

checkInfo();

/**
 * 初始化
 */
function init() {
  checkInfo();
}

/**
 * 选择分类
 */
function pickTab(tab) {
  tabList.forEach(item => {
    item.active = item.id === tab.id;
  });
}

// 当前分类
const activeTab = computed(() => {
  return tabList.find(item => item.active);
});

// 當前語言的banner
const banClaim = computed(() => {
  if (appStore.curLang === 'cn') {
    return 'https://game.legendranch.app/legendaryranch/nft/ban-claim-assets-cn.png';
  } else if (appStore.curLang === 'kn') {
    return 'https://game.legendranch.app/legendaryranch/nft/ban-claim-assets-kn.png';
  } else {
    return 'https://game.legendranch.app/legendaryranch/nft/ban-claim-assets-en.png';
  }
});

// 當前語言的slogan
const sloganClaim = computed(() => {
  if (appStore.curLang === 'cn') {
    return require('@img/claimAssets/img-slogan-cn.png');
  } else if (appStore.curLang === 'kn') {
    return require('@img/claimAssets/img-slogan-kn.png');
  } else {
    return require('@img/claimAssets/img-slogan-en.png');
  }
});

/**
 * 跳转到游戏
 */
function launchToGame() {
  window.open('https://bovine-verse.games/rangeland');
}
</script>

<template>
  <div class="claim-assets-wrap">
    <div class="ban-wrap">
      <img :src="banClaim" alt="" class="ban" style="object-fit: cover; height: 100%" />
      <button class="coming-btn" @click="launchToGame">{{ $t('claimAssets.32') }}</button>
    </div>

    <img :src="sloganClaim" alt="" class="img-slogan" />

    <!-- 选项列表 -->
    <div class="opt-list-wrap">
      <li
        v-for="tab in tabList"
        :key="tab.id"
        :class="[{ active: tab.active }]"
        @click="pickTab(tab)"
      >
        <img :src="tab.img" alt="" class="tab-logo" />
        <div class="tab-name">{{ $t(tab.text) }}</div>
      </li>
    </div>

    <!-- 内容 -->
    <div class="opt-container">
      <TabItem :info="activeTab" @reset="init" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.claim-assets-wrap {
  background-image: url(https://game.legendranch.app/legendaryranch/slideShow/bg-home.webp);
  padding-bottom: 1rem;
  background-size: cover;
}
.ban-wrap {
  /* height: vw(800); */
  @include -height(300px, mvw(700), vw(800));
}
.ban {
  width: 100%;
  height: 100%;
}

.coming-btn {
  /* width: 3.12rem; */
  /* @include -width-a(312); */
  @include -width(svw(312), mvw(312), vw(312));
  @include -height(svw(78), mvw(78), vw(78));
  /* @include -height-a(78); */
  /* height: 0.78rem; */
  position: absolute;
  left: 50%;
  /* top: 86%; */
  @include -top(330px, mvw(700), vw(700));
  transform: translateX(-50%);
  background-image: url(~@img/claimAssets/bg-coming-soon.png);
  background-size: 100% 100%;
  color: #b96110;
  /* font-size: 0.3rem; */
  @include -font-size-a(30);
  font-weight: bold;
}

.img-slogan {
  /* width: 9.4rem; */
  @include -width(svw(400), mvw(800), vw(940));
  /* width: vw(940); */
  margin: 1.27rem auto 0;
}

.opt-list-wrap {
  @include flexPos(center);
  margin-top: 0.58rem;

  @media (max-width: $pad) {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 0.2rem;
    justify-content: center;
    align-items: center;
  }

  > li {
    padding: 0 0.28rem;
    border-radius: 0.26rem;
    color: #fff;
    @include flexPos(center);
    cursor: pointer;

    &.active {
      background-color: #453366;
    }

    .tab-logo {
      width: 0.5rem;
      height: 0.5rem;
    }

    .tab-name {
      font-size: 0.24rem;
      margin-left: 0.05rem;
    }
  }
}

.opt-container {
  /* width: 12rem; */
  @include -width(95%, 95%, 12rem);
  margin: 0.5rem auto 0;
  border: solid 2px #45377b;
  color: #fff;
  border-radius: 20px;
  @include flexPos(center);
  padding: 0.81rem 1.1rem 0.74rem;
  backdrop-filter: blur(5px);
  @media (max-width: $pad) {
    padding: 0.81rem 0.2rem 0.74rem;
  }
}
</style>
