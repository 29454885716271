import { useAppStore } from '@store/appStore';
import { toRaw, reactive } from 'vue';
import { ethers } from 'ethers';
import { BLIND_BOX_CONT, STAR_CONT, CATTLE_CONT } from '@/contracts/polygonAddress';

export async function getTotalSupply() {
  const appStore = useAppStore();

  // 盲盒合约对象
  const boxObj = new ethers.Contract(
    BLIND_BOX_CONT.address,
    BLIND_BOX_CONT.abi,
    toRaw(appStore.ethObj.signer)
  );

  // 星球合约对象
  const starObj = new ethers.Contract(
    STAR_CONT.address,
    STAR_CONT.abi,
    toRaw(appStore.ethObj.signer)
  );

  // 牛牛合约对象
  const cattleObj = new ethers.Contract(
    CATTLE_CONT.address,
    CATTLE_CONT.abi,
    toRaw(appStore.ethObj.signer)
  );

  const boxTotal = boxObj.totalSupply();
  const starTotal = starObj.totalSupply();
  const cattleTotal = cattleObj.creationAmount();

  const [resBoxTotal, resStarTotal, resCattleTotal] = await Promise.all([
    boxTotal,
    starTotal,
    cattleTotal,
  ]);

  return {
    boxTotal: +resBoxTotal,
    starTotal: +resStarTotal,
    // 这里的514是总发行量，如果发行量有变化，记得修改这里
    cattleTotal: +resCattleTotal > 514 ? 514 : +resCattleTotal,
  };
}
