import { useAppStore } from '@store/appStore';
import { BETA_ASSETS_CONT } from '@/contracts/polygonAddress';
import { toRaw, reactive } from 'vue';
import { ElMessage } from 'element-plus';
import { ethers } from 'ethers';
import i18n from '@/utils/i18n.js';
const $t = i18n.global.t;

export default class {
  constructor() {
    this.betaAssetsObj = null;
    this.appStore = {};
    this.createContract();
  }

  createContract() {
    this.appStore = useAppStore();
    // console.log('ethers...', ethers);
    this.betaAssetsObj = new ethers.Contract(
      BETA_ASSETS_CONT.address,
      BETA_ASSETS_CONT.abi,
      toRaw(this.appStore.ethObj.signer)
    );
  }

  async checkInfo() {
    const { formatUnits } = ethers.utils;
    const res = this.betaAssetsObj.checkInfo(this.appStore.defaultAccount);
    // const boxClaimRes = this.betaAssetsObj.boxClaimedAmount(); // 获取盲盒已经领取
    const [resp] = await Promise.all([res]);
    console.log('star....', +resp[1][0]);

    const hasGetBox = resp[0][0]; // 是否领取过盲盒
    const hasGetCalf = resp[0][1]; // 是否领取过牛牛
    const hasGetBvg = resp[0][2]; // 是否领取过bvg
    const hasGetBvt = resp[0][3]; // 是否领取过bvt
    const hasGetStar = resp[0][4]; // 是否领取过星球
    const isCalfWhite = resp[0][5]; // 是否是牛牛白名单
    const isStarWhite = resp[0][6]; // 是否是星球白名单
    const starRes = +resp[1][0]; // 星球剩余数量
    const calfRes = +resp[1][1]; // 牛牛剩余数量
    const bvgGet = formatUnits(resp[1][2] || 0); // bvg领取数量
    const bvtGet = formatUnits(resp[1][3] || 0); // bvt领取数量
    const bvtRes = formatUnits(resp[1][4] || 0); // bvt剩余可领取数量

    // const bvgHasGet = 300000;

    // 已经领取（minted）
    const bvtHasGet = formatUnits(resp[2][0] || 0); //所有人 bvt已經領取的數量
    const bvgHasGet = formatUnits(resp[2][1] || 0); //所有人 bvg已經領取的數量
    const boxHasGet = +resp[2][2]; //所有人 盲盒已經領取的數量
    const starHasGet = +resp[2][3]; //所有人 星球已經領取的數量
    const calfHasGet = +resp[2][4]; //所有人 牛牛已經領取的數量

    // 总量(supply)
    const bvtTotal = +formatUnits(resp[1][4] || 0) + +bvtHasGet;
    // const calfTotal = +resp[1][1] + +resp[2][4];
    const starTotal = +resp[1][0] + +resp[2][3];

    console.log('bvtHadGet...', +bvtHasGet);
    console.log('bvgHadGet...', +bvgHasGet);
    console.log('boxHadGet...', +boxHasGet);
    console.log('starHadGet...', +starHasGet);
    console.log('starRes...', resp);

    return {
      hasGetBox,
      hasGetStar,
      hasGetCalf,
      hasGetBvg,
      hasGetBvt,
      isCalfWhite,
      isStarWhite,
      starRes,
      calfRes,
      bvgGet,
      bvtGet,
      bvtRes,
      bvtHasGet,
      bvgHasGet,
      boxHasGet,
      starHasGet,
      calfHasGet,
      bvtTotal,
      starTotal,
    };
  }

  /**
   * 领取
   */
  async handleClaim(name) {
    const baseGasPrice = this.appStore.ethObj.baseGasPrice;
    return await this.betaAssetsObj[name]({
      gasPrice: 200 * 10 ** 9 + baseGasPrice,
      gasLimit: 600000,
    })
      .then(async (resp) => {
        await resp?.wait?.();
        ElMessage({
          type: 'success',
          message: $t('msg.7'),
        });
        return true;
      })
      .catch((err) => {
        if (err?.data?.message) {
          ElMessage({
            type: 'error',
            message: err?.data?.message,
          });
        } else {
          ElMessage({
            type: 'error',
            message: $t('msg.8'),
          });
        }
        return false;
      });
  }
}
