<script setup>
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue';
import BetaAssetsContract from '@/contractsApi/polygon/BetaAssetsContract';
import { plusXing, handleCopy } from '@/utils/tools';
import { ElMessage } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { useAppStore } from '@/store/appStore';
import i18n from '@/utils/i18n.js';

const appStore = useAppStore();
const $t = i18n.global.t;
const $route = useRoute();

onMounted(() => {
  handleCopy();
});

const checkTimer = ref(null); // 查詢定時器

const props = defineProps({
  info: {},
});

const $emits = defineEmits(['reset']);
const betaAssetsContract = reactive(new BetaAssetsContract());

/**
 * 跳转到谷歌表单申请
 */
function linkForm() {
  window.open(
    'https://docs.google.com/forms/d/e/1FAIpQLSeYilQ9pddQWMMLg0NMsyvAAhwxHguj3Qe0-rv2bus4eUC2NA/viewform'
  );
}

/**
 * 处理领取收益
 */
async function handleClaim() {
  // 国庆前暂时下架
  ElMessage.error($t('msg.64'));
  return;

  // 链不对
  if (!appStore.rightLink) {
    await appStore.chainChanged($route.meta.needChains[0]);
    return;
  }

  // 已经领完了
  if (props.info.banClaim) {
    ElMessage({
      type: 'error',
      message: $t('claimAssets.29'),
    });
    return;
  }

  // 你已经领取了
  if (props.info.claimed) {
    ElMessage({
      type: 'error',
      message: $t('claimAssets.30'),
    });
    return;
  }

  // 你不是白名单
  if (!props.info.eligible) {
    ElMessage({
      type: 'error',
      message: $t('claimAssets.20'),
    });
    return;
  }

  if (props.info.loadClaim || props.info.lockClaim) return;
  clearInterval(checkTimer.value);

  props.info.loadClaim = true;
  const { id } = props.info;

  let res;
  async function _getPlanet() {
    console.log('领取星球');
    return await betaAssetsContract.handleClaim('claimPlanet');
  }

  async function _getCalf() {
    console.log('领取牛牛');
    return await betaAssetsContract.handleClaim('claimCattle');
  }

  async function _getBvg() {
    console.log('领取盲盒');
    return await betaAssetsContract.handleClaim('claimBox');
  }

  async function _getBvt() {
    console.log('领取bvt');
    return await betaAssetsContract.handleClaim('claimBVT');
  }

  async function _getBox() {
    console.log('领取bvg');
    return await betaAssetsContract.handleClaim('claimBVG');
  }

  switch (id) {
    case 1:
      res = await _getPlanet();
      break;
    case 2:
      res = await _getCalf();
      break;
    case 3:
      res = await _getBvg();
      break;
    case 4:
      res = await _getBvt();
      break;
    case 5:
      res = await _getBox();
      break;
  }

  if (res) {
    // 领取完成后，防止延迟问题没能获取最新数据，点击了领取后就锁定
    props.info.lockClaim = true;
  }

  // 领取完成后，重置信息
  props.info.loadClaim = false;

  // 防止網絡問題導致拿不到最新值，輪詢查
  let count = 0;
  checkTimer.value = setInterval(() => {
    $emits('reset');
    count++;
    if (count === 60) {
      clearInterval(checkTimer.value);
    }
  }, 1500);
}

onBeforeUnmount(() => {
  clearInterval(checkTimer.value);
});

function linkPolygon(link) {
  console.log('linkk...', link);
  const baseLink = 'https://polygonscan.com/address/';
  window.open(baseLink + link);
}
</script>

<template>
  <div class="tab-item-wrap">
    <div class="img-wrap">
      <img :src="props.info.img" alt="" class="img-main" />
    </div>
    <div class="name">{{ $t(props.info.name) }}</div>
    <div class="desc">
      <span class="tab-title">{{ $t('claimAssets.27') }}</span
      >: {{ $t(props.info.desc) }}
    </div>

    <div class="contract-wrap box">
      <div class="tab-title">{{ $t('claimAssets.14') }}:</div>
      <div class="addr">
        <div class="link" @click="linkPolygon(props.info.contract)">
          {{ plusXing($t(props.info.contract), 5, 5) }}
        </div>
        <i :data-clipboard-text="$t(props.info.contract)" class="cpy-btn iconfont icon-cpy"></i>
      </div>
    </div>
    <!-- 發行量 -->
    <div class="box">
      <span class="tab-title">{{ $t('claimAssets.15') }}</span
      >:
      <span v-if="+props.info.supply">
        {{ parseInt(props.info.supply)?.toLocaleString?.() }}
      </span>

      <span v-else>
        {{ $t(props.info.supply) }}
      </span>
    </div>
    <!-- 已鑄造 -->
    <div class="box">
      <span class="tab-title">{{ $t('claimAssets.25') }}</span
      >: {{ parseInt(props.info.minted)?.toLocaleString?.() }}
    </div>
    <div class="box">
      <span class="tab-title"> {{ $t('claimAssets.16') }}</span
      >: {{ $t(props.info.whoEligible) }}
    </div>
    <div class="box" v-show="props.info.eligible">
      <!-- 可领取的 -->

      <span class="tab-title">{{ $t('claimAssets.18') }}: </span>
      {{ !props.info.claimed ? props.info.canGet?.toLocaleString?.() : 0 }};

      <!-- 已领取的 -->
      <span style="margin-left: 0.3rem"
        ><span class="tab-title">{{ $t('claimAssets.19') }}:</span>
        {{ props.info.claimed ? props.info.canGet?.toLocaleString?.() : 0 }}</span
      >
    </div>
    <!-- 如果不符合条件则 -->
    <div class="box" v-show="!props.info.eligible">{{ $t('claimAssets.20') }}</div>

    <div class="btns-wrap">
      <button
        v-loading="props.info.loadClaim"
        :class="{
          disable:
            props.info.claimed ||
            props.info.banClaim ||
            !props.info.eligible ||
            !appStore.rightLink ||
            props.info.lockClaim,
        }"
        @click="handleClaim"
      >
        {{ $t('claimAssets.9') }}
      </button>
      <button v-show="props.info.id === 1" @click="linkForm">
        {{ $t('claimAssets.10') }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$gap: 0.3rem;

.tab-item-wrap {
  font-size: 0.24rem;

  > div {
    @media (max-width: $phone) {
      width: 5rem;
    }
  }
}
.img-wrap {
  width: 5rem;
  background-color: #310f65ba;
  margin: 0 auto;
  border-radius: 20px;

  .img-main {
    width: 100%;
    height: 100%;
  }
}

.name {
  font-size: 0.36rem;
  text-align: center;
  margin-top: 0.37rem;
}

.desc {
  margin-top: 0.4rem;
  font-size: 0.24rem;
  line-height: 1.5;
}

.contract-wrap {
  @include flexPos(flex-start);

  .addr {
    display: flex;
    margin: 0 0 0 0.1rem;
  }

  .link:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .cpy-btn {
    font-size: 0.25rem;
    margin-left: 0.1rem;
    cursor: pointer;
  }
}

.btns-wrap {
  @include flexPos(center);
  margin-top: 1.97rem;

  button {
    width: 3.2rem;
    background-color: #fff;
    color: #3a0fad;
    border-radius: 35px;
    padding: 0.13rem 0;
    font-weight: bold;

    &:nth-last-child(1) {
      margin-left: 0.6rem;
    }

    &.disable {
      background-color: rgb(172, 172, 172);
      color: #fff;
    }
  }
}

.tab-title {
  font-weight: bold;
}

.box {
  margin-top: $gap;
}
</style>
